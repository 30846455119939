require('./components/global')
require('./components/main-page')
require('./components/about')
require('./components/treatment')
require('./components/header')
require('./components/docs')
require('./components/answers')
require('./components/symptoms')
require('./components/modals')
require('./components/vacancies')
require('./components/reviews')
require('./components/doctor')
require('./components/slider')
require('./components/preloader')
require('./components/switcher')

